import { useState, useRef } from 'react';
import { useGLTF, PresentationControls, Html } from '@react-three/drei';
import { RigidBody, Physics, CuboidCollider } from '@react-three/rapier';

// Helper function to enable shadows on a scene
const useEnableShadows = (...scenes) => {
    scenes.forEach(scene => {
        scene.traverse((object) => {
            if (object.isMesh) {
                object.castShadow = true;
                object.receiveShadow = true;
            }
        });
    });
};

// Helper function to generate a random float between -1 and 1
const getRandomFloat = () => Math.random() * 2 - 1;

// Function to make an object jump with a slight spin
const makeJump = (ref) => {
    const torqueStrength = 0.005;
    if (ref.current) {
        ref.current.wakeUp();
        ref.current.applyImpulse({ x: 0, y: 0.2, z: 0 });
        ref.current.applyTorqueImpulse({
            x: getRandomFloat() * torqueStrength,
            y: getRandomFloat() * torqueStrength,
            z: getRandomFloat() * torqueStrength,
        });
    }
};

export default function PCExperience() {
    // Load models
    const { scene: mouseScene } = useGLTF('./mouse.glb');
    const { scene: monitorScene } = useGLTF('./monitor.glb');
    const { scene: keyboardWhiteScene } = useGLTF('./keyboard.glb');
    const { scene: keyboardPinkScene } = useGLTF('./keyboard_color.glb');
    const { scene: plantPurpleScene } = useGLTF('./plant_pot1.glb');
    const { scene: plantPinkScene } = useGLTF('./plant_pot2.glb');

    // Enable shadows for all models
    useEnableShadows(mouseScene, monitorScene, keyboardWhiteScene, keyboardPinkScene, plantPurpleScene, plantPinkScene);

    // State management for switching models
    const [isKeyboardWhite, setIsKeyboardWhite] = useState(true);
    const [isPlantPurple, setIsPlantPurple] = useState(true);
    const [showIframe, setShowIframe] = useState(false);

    // Refs for RigidBodies
    const mouseRef = useRef();

    // Toggles for model switching
    const toggleKeyboard = () => setIsKeyboardWhite(!isKeyboardWhite);
    const togglePlant = () => setIsPlantPurple(!isPlantPurple);
    // const toggleIframe = () => setShowIframe(!showIframe);
    const toggleIframe = () => setShowIframe(showIframe);

    return (
        <>
            <directionalLight
                castShadow
                position={[5, 5, 5]}
                intensity={1.5}
                shadow-normalBias={0.04}
                shadow-mapSize={[1024, 1024]}
                color={"#FFF4F3"}
            />
            <ambientLight intensity={1.5} />

            <PresentationControls
                global
                rotation={[0, -0.3, 0]}
                polar={[-Math.PI / 5, Math.PI / 5]}
                azimuth={[-Math.PI / 5, Math.PI / 5]}
            >
                <Physics>
                    {/* Ground plane */}
                    <RigidBody type="fixed">
                        <CuboidCollider args={[10, 0.1, 10]} position={[0, -1.1, 0]} />
                    </RigidBody>

                    {/* Mouse Object with Jump on Click */}
                    <RigidBody ref={mouseRef} restitution={0}>
                        <primitive
                            object={mouseScene}
                            scale={1}
                            position={[1.7, -1, 1]}
                            rotation={[0, -0.2, 0]}
                            onClick={() => makeJump(mouseRef)}
                        />
                    </RigidBody>
                </Physics>

                {/* Monitor with Clickable Iframe */}
                <mesh onClick={toggleIframe} position={[0, -1, 0]}>
                    <primitive object={monitorScene} scale={1} />
                    {showIframe && (
                        <Html
                            transform
                            wrapperClass="htmlScreen"
                            distanceFactor={2}
                            position={[0, 1.56, -0.65]}
                        >
                            <iframe
                                src="https://feimcodi.vercel.app/info"
                                width="620"
                                height="375"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </Html>
                    )}
                </mesh>

                {/* Other Interactive Objects */}
                <primitive object={isKeyboardWhite ? keyboardWhiteScene : keyboardPinkScene} scale={1} position={[0, -1, 0]} onClick={toggleKeyboard} />
                <primitive object={isPlantPurple ? plantPurpleScene : plantPinkScene} scale={0.4} position={[-2.2, -1, 0]} rotation={[0, 1, 0]} onClick={togglePlant} />
            </PresentationControls>
        </>
    );
}
