import React, { useState } from "react";
import Header from '../Header';
import Pagination from '../Pagination'
import quizData from './QuizData';
import './QuizPage.css';


function QuizPage() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNextClick = () => {
    if (currentIndex < quizData.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedAnswer(null);
    }
  };


  return (
    <div className="quiz-page-container">
      <Header isMainHeader={false} />
       <div className="quiz-container">
          {quizData[currentIndex].type === "intro" || quizData[currentIndex].type === "outro" ? (
            <div className="intro-slide">
              <h1>{quizData[currentIndex].title}</h1>
              <p>{quizData[currentIndex].description}</p>
              {quizData[currentIndex].type === "intro" && (
                <button onClick={handleNextClick}>Començar</button>
              )}
            </div>
          ) : (
            <div className="question-slide">
              <div className="question-container">
                <h2>{quizData[currentIndex].question}</h2>
                <div className="answers">
                  {quizData[currentIndex].answers.map((answer, index) => (
                    <button
                      key={index}
                      className={`answer-btn ${selectedAnswer ? "disabled" : ""}`}
                      onClick={() => handleAnswerClick(quizData[currentIndex].fact)}
                      disabled={selectedAnswer !== null}
                    >
                      {answer}
                    </button>
                  ))}
                </div>
                {selectedAnswer && (
                  <div className="fact">
                    <p>{quizData[currentIndex].fact}</p>
                    <button onClick={handleNextClick}>Següent</button>
                  </div>
                )}
              </div>
              {/* Mostrem la barra de progrés només si no estem a la intro */}
              <div className="progress-bar">
                {quizData.slice(1, -1).map((_, index) => (
                  <div key={index} className={`dot ${index + 1 === currentIndex ? "active" : ""}`} />
                ))}
              </div>
            </div>
          )}
        </div>
      <Pagination prevPage="/referents"/>
    </div>
  );
}

export default QuizPage;
