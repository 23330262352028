const quizData = [
  {
    type: "intro",
    title: "És l'enginyeria per a tu?",
    description: "Descobreix si els teus interessos i habilitats encaixen amb el món de l’enginyeria i la programació!",
  },
  {
    question: "Com et sents davant d’un repte o problema nou?",
    answers: ["Intent analitzar-lo i trobar una solució pràctica", "M’estressa, preferesc que qualcú altre ho solucioni"],
    fact: "Cada gran invent va començar com un problema sense resoldre. L’enginyeria i la programació són, sobretot, trobar solucions! No és qüestió de tenir totes les respostes, sinó de fer-se les preguntes correctes!"
  },
  {
    question: "T’agradaria dedicar-te a la tecnologia però et preocupa el nivell de mates o física?",
    answers: ["Sí, crec que és imprescindible per entendre-ho tot", "Crec que el més important és la lògica i les ganes d’aprendre"],
    fact: "La programació i l’enginyeria són més qüestió de lògica, creativitat i resolució de problemes que de fer càlculs complicats."
  },
  {
    question: "T’agrada crear coses, encara que sigui dibuixar, fer manualitats o editar vídeos?",
    answers: ["Sí, m’encanta fer coses creatives", "No, no m’interessen aquestes coses"],
    fact: "L’enginyeria no és només números! Programar, dissenyar aplicacions o crear mons en 3D també és ser creativa."
  },
  {
    question: "Alguna vegada has tengut una idea per millorar una tasca quotidiana?",
    answers: ["Sí, sempre penso en millores per fer la vida més fàcil", "A vegades, però no sé com dur-les a terme"],
    fact: "Moltes enginyeres i programadores van començar així! Pensant: \"Això es podria fer millor\"… i després van aprendre com fer-ho! "
  },
  {
    question: "Creus que la tecnologia encara es veu com una cosa ‘de nins‘?",
    answers: ["Sí, però crec que això està canviant", "No, qualsevol persona pot fer tecnologia"],
    fact: "No deixis que els estereotips decideixin per tu, la tecnologia és per a qui vulgui crear, innovar i resoldre problemes! Les dones han fet grans contribucions en tecnologia i enginyeria!"
  },
  {
    question: "Et veus fent feina en un entorn tecnològic o creus que no tens el perfil?",
    answers: ["Sí, m’agradaria molt", "No, no me veig en aquest món"],
    fact: "No hi ha un únic perfil per treballar en tecnologia! Es necessiten persones creatives, analítiques, comunicatives i molt més"
  },
  {
    question: "Has pensat mai que la tecnologia pot ajudar a fer un món millor?",
    answers: ["Sí, la tecnologia pot solucionar molts problemes", "No, no crec que sigui tan important"],
    fact: "La tecnologia està millorant el món cada dia: des de la salut fins a la sostenibilitat! Potser tu podries ajudar a fer el món millor amb tecnologia."
  },
  {
    type: "outro",
    title: "Has arribat al final!",
    description: "Sigui quina sigui la teva resposta, el més important és que sàpigues que l’enginyeria i la programació estan obertes per a tothom. No has de ser una experta en matemàtiques ni encaixar en un perfil concret, només necessites curiositat, creativitat i ganes d’aprendre!",
  },
];


export default quizData;