const careersData = [
  {
    id: 1,
    name: 'Enginyeria en Sistemes Audiovisuals',
    description:
      "L’Enginyeria en Sistemes Audiovisuals forma professionals capaços de dissenyar, desenvolupar i gestionar tecnologies relacionades amb la captació, processament, transmissió i reproducció de continguts audiovisuals. Aquest grau combina coneixements d’enginyeria, informàtica i telecomunicacions, amb un enfocament en àudio, vídeo, imatge i sistemes multimèdia. Els estudiants adquireixen competències en àrees com el processament de senyals, les xarxes de comunicació i la integració de tecnologies audiovisuals en diversos entorns.",
    profile: "Aquesta carrera és ideal per a persones interessades en combinar la tecnologia amb la creativitat, oferint una formació que permet treballar en sectors altament innovadors i dinàmics dins del camp de les telecomunicacions i els mitjans audiovisuals.",
    universities: [
      'UPF: Enginyeria en Sistemes Audiovisuals',
      'UPC: Enginyeria de Tecnologies i Serveis de Telecomunicació - Menció en Sistemes Audiovisuals'
    ],
    industries: [
      'Producció audiovisual i cinema',
      'Telecomunicacions i mitjans de comunicació',
      'Tecnologies multimèdia i desenvolupament de software',
      'Broadcast, televisió i ràdio',
      'Indústria de l’entreteniment i espectacles',
    ],
    opportunities: [
      'Enginyera de sistemes audiovisuals',
      'Tècnica de so i imatge',
      'Desenvolupadora de sistemes multimèdia',
      'Responsable de tecnologies de la informació i comunicació audiovisual',
      'Enginyer de so per a sales de cinema',
      'Desenvolupador de sistemes de so 3D',
    ],
    difficulty: 3,
    technicalLevel: 4,
    creativity: 4.5,
    versatility: 4,
    jobOpportunities: 4,
    link: "https://drive.google.com/file/d/1zjHMq_YMboKiuSedYeHN0eDWbBoE5NQb/preview",
    img:"esa.png",
  },
  {
    id: 2,
    name: 'Intel·ligència Artificial',
    description:
      "El Grau en Intel·ligència Artificial prepara professionals amb una formació tècnica i científica per dissenyar, desenvolupar i aplicar sistemes intel·ligents capaços d'aprendre, raonar i prendre decisions de manera autònoma. Els estudiants adquireixen coneixements en programació, aprenentatge automàtic (machine learning), xarxes neuronals, robòtica, anàlisi de dades i altres tecnologies avançades que permeten la creació d'aplicacions innovadores en diversos sectors. Aquest grau se centra a donar resposta a les necessitats tecnològiques i socials de la quarta revolució industrial, on la IA és clau.",
    profile: "Aquest grau és ideal per a persones amb una forta motivació per entendre i aplicar la intel·ligència artificial en la resolució de problemes complexos i per innovar en sectors que requereixen solucions tecnològiques avançades i intel·ligents.",
    universities: [
      'UPC: Grau en Intel·ligència Artificial',
      'UAB: Grau en Intel·ligència Artificial'
    ],
    industries: [
      'Tecnologia i programari',
      'Salut i biotecnologia',
      'Finances i banca',
      'Automoció i robòtica',
      'Màrqueting digital i comerç electrònic',
    ],
    opportunities: [
      'Enginyera d\'intel·ligència artificial',
      'Científica de dades',
      'Desenvolupadora de models d\'aprenentatge automàtic',
      'Especialista en robòtica i automatització',
      'Analista de sistemes intel·ligents',
      'Desenvolupadora d\'algoritmes d\'intel·ligència artificial',
    ],
    difficulty: 4,
    technicalLevel: 5,
    creativity: 4,
    versatility: 4,
    jobOpportunities: 5,
    link: "https://drive.google.com/file/d/1NGgYxIyGO4Dt1PXe551UlDqvjc43qnII/preview",
    img:"ia.png",
  },
  {
    id: 3,
    name: 'Enginyeria i Ciència de Dades',
    description:
      "El Grau en Enginyeria i Ciència de Dades forma professionals capaços de recollir, gestionar, analitzar i interpretar grans volums de dades per extreure’n coneixement útil i aplicar-lo en la presa de decisions. Aquesta carrera combina matemàtiques, estadística, programació i enginyeria per desenvolupar solucions innovadores que permeten optimitzar processos i predir comportaments en diferents sectors. Els estudiants aprenen tècniques d’aprenentatge automàtic, anàlisi de dades massives (big data), visualització de dades, i sistemes d’informació.",
    profile: "Aquest grau és ideal per a persones interessades a combinar la programació amb les matemàtiques per resoldre problemes complexos mitjançant l’anàlisi de dades. És una carrera amb una demanda creixent i un gran potencial d’ocupació en sectors innovadors i tecnològics.",
    universities: [
      'UPF: Grau en Enginyeria Matemàtica en Ciència de Dades',
      'UPC: Grau en Enginyeria i Ciència de Dades',
      'UAB: Grau en Enginyeria de Dades'
    ],
    industries: [
      'Tecnologia i telecomunicacions',
      'Salut i farmacèutica',
      'Finances i assegurances',
      'Màrqueting i comerç electrònic',
      'Consultoria i recerca científica',
    ],
    opportunities: [
      'Enginyera de dades',
      'Científica de dades',
      'Analista de big data',
      'Especialista en intel·ligència empresarial',
      'Desenvolupadora d\'algoritmes d\'aprenentatge automàtic',
    ],
    difficulty: 3.5,
    technicalLevel: 4.5,
    creativity: 3,
    versatility: 4.5,
    jobOpportunities: 5,
    link: "https://drive.google.com/file/d/16HmAAjYhG_5MynehM7uqOeAgIJmLUeEG/preview",
    img:"dades.png",
  },
  {
    id: 4,
    name: 'Enginyeria Informàtica',
    description:
      "El Grau en Enginyeria Informàtica està dissenyat per formar professionals capaços de dissenyar, desenvolupar i gestionar sistemes informàtics complexos, amb un enfocament profund en la programació i el pensament computacional. A diferència d'altres carreres enfocades en aplicacions específiques, l'enginyeria informàtica posa l'accent en la programació, els algorismes, l'arquitectura de sistemes i la ciberseguretat, proporcionant una sòlida base tècnica adaptable a diverses indústries.",
    profile: "Aquest grau és ideal per a persones motivades per aprendre a programar bé i entendre com funcionen els sistemes informàtics des de dins. Ofereix una base sòlida en programació i enginyeria del software, permetent aplicar coneixements a una gran varietat de problemes i sectors.",
    universities: [
      'UIB: Grau d\'Enginyeria Informàtica',
      'UPC: Grau en Enginyeria Informàtica',
      'UPF: Grau en Enginyeria Informàtica',
      'UB: Grau en Enginyeria Informàtica',
      'UAB: Grau en Enginyeria Informàtica',
    ],
    industries: [
      'Desenvolupament de software i aplicacions',
      'Tecnologies de la informació i comunicació (TIC)',
      'Ciberseguretat i protecció de dades',
      'Consultoria tecnològica',
      'Intel·ligència artificial i ciència de dades',
    ],
    opportunities: [
      'Enginyera de software',
      'Desenvolupadora full-stack',
      'Analista de sistemes informàtics',
      'Administradora de sistemes i xarxes',
      'Consultora en seguretat informàtica',
    ],
    difficulty: 4,
    technicalLevel: 5,
    creativity: 3.5,
    versatility: 4.5,
    jobOpportunities: 5,
    link: "https://drive.google.com/file/d/1E_Ug3FZH4DqWuDryE-aG1ZYATkA2uTW1/preview",
    img:"informatica.png",
  },
  {
    id: 5,
    name: 'Enginyeria Biomèdica',
    description:
      "El Grau en Enginyeria Biomèdica combina l’enginyeria amb les ciències de la salut per dissenyar i desenvolupar tecnologies que milloren la qualitat de vida de les persones. Aquesta carrera proporciona una formació multidisciplinària en matemàtiques, física, biologia, i enginyeria per aplicar-les al camp biomèdic. Els estudiants adquireixen coneixements en el disseny de dispositius mèdics, processament de senyals biomèdics, biotecnologia, imatge mèdica i la gestió de dades de salut, amb l’objectiu de desenvolupar solucions innovadores per a la diagnosi, tractament i monitorització de malalties.",
    profile: "Aquest grau és ideal per a aquelles persones que volen aplicar els seus coneixements tècnics i científics per millorar la salut humana, combinant la passió per la tecnologia amb la voluntat de contribuir al benestar de la societat.",
    universities: [
      'UPC: Grau en Enginyeria Biomèdica',
      'UPF: Grau en Enginyeria Biomèdica',
      'UB: Grau en Enginyeria Biomèdica'
    ],
    industries: [
      'Salut i hospitals',
      'Dispositius mèdics i biotecnologia',
      'Farmacèutica i recerca clínica',
      'Tecnologies de la informació en salut',
      'Rehabilitació i robòtica mèdica',
    ],
    opportunities: [
      'Enginyera biomèdica',
      'Desenvolupadora de dispositius mèdics',
      'Especialista en imatge mèdica',
      'Investigadora en biotecnologia',
      'Consultora en tecnologies sanitàries',
    ],
    difficulty: 3.5,
    technicalLevel: 4,
    creativity: 3.5,
    versatility: 4,
    jobOpportunities: 4.5,
    link: "https://drive.google.com/file/d/1jGYkpsTnOJA6VlnHIfyGozozZIdjNMLm/preview",
    img:"bio.png",
  },
  // {
  //   id: 6,
  //   name: 'Disseny i Desenvolupament de Videojocs i Continguts Interactius',
  //   description:
  //     "El Grau en Disseny i Desenvolupament de Videojocs i Continguts Interactius se centra en la creació i el disseny d’experiències digitals, videojocs i altres aplicacions interactives. Aquestes carreres combinen la creativitat amb el coneixement tècnic, però amb un enfocament més artístic i visual, ideal per a aquelles persones interessades en l’art digital, l’animació, el disseny gràfic i la narrativa interactiva. Els estudiants aprenen a conceptualitzar, dissenyar i desenvolupar videojocs, aplicacions educatives, i continguts multimèdia, fent ús d’eines de disseny, programari de creació de jocs i tècniques de narració.",
  //   profile: "Aquestes carreres són ideals per a persones creatives que volen treballar en projectes innovadors en el món del disseny digital i els videojocs, buscant un equilibri entre la tecnologia i l’art. Amb menys pes en la programació avançada i més èmfasi en l’expressió artística, són perfectes per a aquelles que volen explorar el món dels videojocs des d’una perspectiva més visual i conceptual.",
  //   universities: [
  //     'UB: Grau en Disseny i Desenvolupament de Videojocs i Jocs Aplicats',
  //     'UPC (Terrassa): Grau en Disseny i Desenvolupament de Videojocs',
  //     'UPC (Terrassa): Grau en Disseny Digital i Tecnologies Multimèdia'
  //   ],
  //   industries: [
  //     'Videojocs i entreteniment interactiua',
  //     'Animació i efectes visuals',
  //     'Màrqueting digital i publicitat interactiva',
  //     'Educació i formació digital',
  //     'Disseny d\'experiències interactives per a museus i exposicions',
  //   ],
  //   opportunities: [
  //     'Dissenyadora de videojocs',
  //     'Artista digital i animadora 3D',
  //     'Desenvolupadora de jocs aplicats',
  //     'Especialista en UX/UI per a jocs i aplicacions',
  //     'Guionista i dissenyadora de narratives interactives',
  //   ],
  //   difficulty: 3,
  //   technicalLevel: 3.5,
  //   creativity: 5,
  //   versatility: 3.5,
  //   jobOpportunities: 3.5,
  // }
];

export default careersData;