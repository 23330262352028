import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ prevPage, nextPage }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="pagination">
      {prevPage && (
        <Link to={prevPage} className="pagination-link" onClick={handleClick}>
          Pàgina anterior
        </Link>
      )}
      {nextPage && (
        <Link to={nextPage} className="pagination-link" onClick={handleClick}>
          Pàgina següent
        </Link>
      )}
    </div>
  );
};

export default Pagination;
