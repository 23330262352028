import React from 'react';
import Header from '../Header';
import ReferentsCards from './ReferentsCards'
import Pagination from '../Pagination'
import './ReferentsPage.css';


function ReferentsPage() {
  return (
    <div className="referents-page-container">
      <Header isMainHeader={false} />
      <div className="intro">
        <h1>Inspira't amb Referents</h1>
        <p>El món de la tecnologia està ple d’oportunitats increïbles, però per avançar, cal trencar estereotips i obrir camí per a tothom. En aquesta pàgina descobriràs dones referents que estan revolucionant el sector, compartint els seus coneixements i demostrant que els límits només els posem nosaltres.</p>
        <p>Tant si busques inspiració, idees per començar la teva carrera o simplement una empenta per seguir endavant, aquí trobaràs històries que motiven, perfils apassionants i podcasts creats per dones que lideren amb força i visió. Explora, aprèn i recorda: tu també pots marcar la diferència en el món tecnològic!</p>
      </div>
      <div className="referents-container">
        <ReferentsCards />
      </div>
      <Pagination prevPage="/info" nextPage="/quiz"/>
    </div>
  );
}

export default ReferentsPage;
